<template>
    <b-form-group
        :label="title"
        class="mb-0"
        label-class="font-weight-bold pt-0"
        label-size="md"
    >
        <b-card no-body>
            <b-tabs card pills>
                <b-tab v-for="locale in getDataLocale"
                       :active="locale.locale === $i18n.locale"
                       :title="locale.locale" class="border-danger"
                       style="background: white"
                >
                    <b-col class="p-0 pr-4 " cols="6">
                        <!-- name -->
                        <b-form-group
                            :label="$t('form.name')"
                            class="mb-2 mr-sm-02 mb-sm-0"
                            description=""
                            label-class="mb-0"
                            label-for="meta_name"
                        >
                            <b-form-input
                                :id="'name_' + locale.locale"
                                v-model.lazy="record[locale.locale].name"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                type="text"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-tab>
            </b-tabs>
        </b-card>
    </b-form-group>
</template>

<script>
export default {
    name: "FormProductBasicLocale",
    components: {},
    props: {
        title: {
            type: String,
            default() {
                return this.$t('form.tab.locale');
            }
        },
        data_locale: {
            type: Array
        },
        record: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {
        getDataLocale() {
            if (this.__isNull(this.data_locale)) {
                return null;
            }
            // <-- update record with locale -->
            this.data_locale.forEach(locale => {
                if (this.record[locale.locale] === undefined) {
                    this.$set(this.record, locale.locale, {});
                }
            });
            // <--  -->
            return this.data_locale;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
